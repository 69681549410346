import { CSSProperties } from 'react'
import css from './ToggleInput.module.css'
import classNames from 'classnames/bind'

const cx = classNames.bind(css)

type Props = {
  isActive: boolean | undefined
  label?: string
  style?: CSSProperties
  onToggle: () => void
  isDisabled?: boolean
}

const ToggleInput = ({
  isActive,
  label,
  style,
  onToggle,
  isDisabled,
}: Props) => {
  return (
    <div className={cx('wrapper', { disabled: isDisabled })} style={style}>
      <div
        className={cx('container')}
        onClick={!isDisabled ? () => onToggle() : () => {}}
      >
        <div className={cx('toggle', { active: isActive })}>
          <div className={cx('pipka')} />
        </div>
        {label && <div>{label}</div>}
      </div>
    </div>
  )
}

export default ToggleInput
