import React, { useState } from 'react'
import { useCombobox, useMultipleSelection, useSelect } from 'downshift'
import { X } from 'react-feather'
import css from './MultiSelect.module.css'
import classNames from 'classnames/bind'
import { Highlight } from '../../atoms/Highlight/Highlight'
import { matchSorter } from 'match-sorter'

const cx = classNames.bind(css)

interface Item {
  id: string
  name: string
}

interface MultiSelectProps {
  items: Item[]
  placeholder?: string
  selectedItems: Item[]
  onSelectedItemsChange: (items: Item[]) => void
  isError?: boolean
}

const MultiSelect: React.FC<MultiSelectProps> = ({
  placeholder,
  items,
  selectedItems,
  onSelectedItemsChange,
  isError,
}) => {
  //   const [selectedItems, setSelectedItems] = useState<Item[]>([])
  const [inputValue, setInputValue] = useState('')
  const inputRef = React.useRef<HTMLInputElement>(null)

  const filteredItems = items.filter(
    (item) => !selectedItems.some((selected) => selected.id === item.id)
  )

  const matches =
    inputValue && inputValue.trim().length > 0
      ? matchSorter(filteredItems, inputValue.trim().replaceAll('-', ' '), {
          keys: [(item) => item.name.replaceAll('-', ' ')],
          threshold: matchSorter.rankings.WORD_STARTS_WITH,
        })
      : filteredItems

  const {
    isOpen,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    getItemProps,
    highlightedIndex,
    openMenu,
  } = useCombobox({
    items: matches,
    itemToString(item) {
      return item ? item.name : ''
    },
    defaultHighlightedIndex: 0, // after selection, highlight the first item.
    selectedItem: null,
    inputValue,
    stateReducer(state, actionAndChanges) {
      const { changes, type } = actionAndChanges

      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
          if (changes.selectedItem) {
            onSelectedItemsChange([...selectedItems, changes.selectedItem])
            setInputValue('')
          }
          return {
            ...changes,
            isOpen: true, // keep the menu open after selection.
            //highlightedIndex: 0, // with the first option highlighted.
          }
        default:
          return changes
      }
    },

    onStateChange({
      inputValue: newInputValue,
      type,
      selectedItem: newSelectedItem,
    }) {
      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
        case useCombobox.stateChangeTypes.InputBlur:
          if (newSelectedItem) {
            setInputValue('')
            onSelectedItemsChange([...selectedItems, newSelectedItem])
          }
          break

        case useCombobox.stateChangeTypes.InputChange:
          setInputValue(newInputValue || '')

          break
        default:
          break
      }
    },
  })

  const removeItem = (itemToRemove: Item) => {
    onSelectedItemsChange(
      selectedItems.filter((item) => item.id !== itemToRemove.id)
    )
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (
      event.key === 'Backspace' &&
      inputValue === '' &&
      selectedItems.length > 0
    ) {
      const lastItem = selectedItems[selectedItems.length - 1]
      removeItem(lastItem)
    }
  }

  return (
    <div className={cx('container', { error: isError })}>
      <div
        className={css.inputContainer}
        {...getComboboxProps()}
        onClick={() => {
          if (inputRef.current) {
            inputRef.current.focus()
          }
        }}
      >
        {selectedItems.map((item) => (
          <div key={item.id} className={css.tag}>
            {item.name}
            <X
              className={css.removeIcon}
              size={16}
              onClick={() => removeItem(item)}
            />
          </div>
        ))}
        <input
          className={cx('input')}
          {...getInputProps({
            placeholder: selectedItems.length ? '' : placeholder,
            className: css.input,
            onFocus: () => openMenu(), // Open menu on input focus
            onKeyDown: handleKeyDown, // Handle backspace to remove last item
            //value: inputValue,
            ref: inputRef,
          })}
        />
      </div>
      <div className={cx('options-list')}>
        <ul {...getMenuProps()} className={css.menu}>
          {isOpen &&
            matches.map((item, index) => (
              <li
                key={item.id}
                {...getItemProps({
                  item,
                  index,
                  style: {
                    backgroundColor:
                      highlightedIndex === index
                        ? 'var(--accent-highlight)'
                        : 'white',
                  },
                })}
                className={`${css.menuItem} ${
                  highlightedIndex === index ? css.highlighted : ''
                }`}
              >
                <Highlight highlight={inputValue}>{item.name}</Highlight>
              </li>
            ))}
          {isOpen && matches.length === 0 && (
            <li className={css.menuItem}>No items found</li>
          )}
        </ul>
      </div>
    </div>
  )
}

export default MultiSelect
