import { DateTime } from 'luxon'
import React, { useEffect, useState } from 'react'
import { GymMemberData } from '../../types/types'
import Button from '../buttons/Button/Button'
import Avatar from '../atoms/Avatar/Avatar'
import Modal, { ModalBasicProps } from './Modal'
import css from './Modal.module.css'
import useGym from '../../hooks/useGym'

type Props = {
  isVisible: boolean
  isDestructive?: boolean
  onConfirm: () => void
  onDismiss: () => void
  isLoading?: boolean
  title: string
  text?: string
  buttonLabels?: { primary: string; secondary: string }
}

const Confirm = ({
  isVisible,
  isDestructive,
  title,
  text,
  onConfirm,
  onDismiss,
  isLoading,
  buttonLabels,
}: Props) => {
  //const [isLoading, setLoading] = useState(false)

  const buttons: ModalBasicProps['buttons'] = {
    secondary: {
      onClick: onDismiss,
      label: buttonLabels ? buttonLabels.secondary : 'Dismiss',
    },
    primary: {
      label: buttonLabels ? buttonLabels.primary : 'Confirm',
      onClick: onConfirm,
      isLoading,
      type: isDestructive ? 'destructive' : undefined,
    },
  }

  return (
    <Modal
      isVisible={isVisible}
      onCancel={onDismiss}
      size="small"
      buttons={buttons}
      title={title}
    >
      <div style={{ display: 'flex', flexDirection: 'column' }}>{text}</div>
    </Modal>
  )
}

export default Confirm
