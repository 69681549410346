import React, { useState, useEffect, useContext } from 'react'
import css from './Sidebar.module.css'
import Button from '../../buttons/Button/Button'
import ButtonArea from '../../buttons/ButtonArea/ButtonArea'
import WorkoutBuilder from '../../forms/WorkoutBuilder/WorkoutBuilder'
import WorkoutCard from '../../cards/WorkoutCard/WorkoutCard'
import { X as IconClose, PlusCircle } from 'react-feather'
import { DateTime } from 'luxon'

import {
  restrictToVerticalAxis,
  restrictToWindowEdges,
} from '@dnd-kit/modifiers'

import {
  DndContext,
  DragOverlay,
  closestCenter,
  MouseSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core'

import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'
import ButtonIcon from '../../buttons/ButtonIcon/ButtonIcon'
import { RewodLegacyEvent } from '../../../types/legacyEvents'
import useWorkoutBuilder from '../../../hooks/useWorkoutBuilder'
import CalendarContext from '../../../context/CalendarContext'
import { Tooltip } from 'react-tooltip'
import { AppContext } from '../../../context/AppContext'
import { UserContext } from '../../../context/UserContext'
import { DateSubheader } from '../../atoms/DateSubheader/DateSubheader'
import Sidebar from './Sidebar'
import WorkoutAddInput from '../../inputs/WorkoutAddInput/WorkoutAddInput'
import { Workout, WorkoutShortcut } from '../../../types/workouts'
import Confirm from '../../modals/Confirm'

import { v4 as uuid } from 'uuid'
import { useWorkoutPublisher } from '../../../hooks/useWorkoutPublisher'
import useMovements from '../../../hooks/useMovements'
import { Analytics } from '../../../services/analytics'
import { CalendarEvent } from '../../../types/calendarEvents'
import { IconNewWorkout } from '../../atoms/Icons/Icons'
import useCalendarEventSorter from '../../../hooks/calendar/useCalendarEventSorter'
import makeVariationOfPredefinedWorkoutEvent from '../../../utilities/workout builder/makeVariationOfWorkoutEvent'
import getNewWorkoutEventWithMovement from '../../../utilities/workout builder/getNewWorkoutEventWithMovement'
import useCalendarEvents from '../../../hooks/calendar/useCalendarEvents'

type Props = {
  isActive: boolean
  onDismiss: () => void
  builderMode?: boolean
}

type SidebarMode = {
  data?: CalendarEvent.Workout
  type: 'view' | 'edit' | 'add-single' | 'add-superset'
  index?: number
}

const CalendarSidebar = ({ isActive, onDismiss, builderMode }: Props) => {
  const { activeDate, setPreventActiveDateChange } = useContext(CalendarContext)
  const { data: currentDayEvents } = useCalendarEvents({
    dateISO: activeDate || '',
    enabled: true,
  })
  //const currentDayEvents = (activeDate && getEventsForDate(activeDate)) || null

  const sidebarRef = React.useRef<HTMLDivElement>(null)

  const dateObj = activeDate ? DateTime.fromISO(activeDate) : null
  const dayOfWeek = activeDate ? DateTime.fromISO(activeDate).weekdayLong : null
  const fullDate = dateObj
    ? dateObj.toLocaleString(DateTime.DATE_MED)
    : 'No date'

  const [mode, setMode] = useState<SidebarMode>({
    type: builderMode ? 'add-single' : 'view',
  })

  const [editExercise] = useState<{
    data: RewodLegacyEvent.Item
    index: number
  } | null>(null)

  const { currentSpace, currentGymTrack } = useContext(AppContext)
  const { userID } = useContext(UserContext)
  const canEdit =
    currentSpace?.permissions.canEditWorkouts ||
    currentSpace?.ownerID === userID

  const getRevelationMessage = () => {
    if (!activeDate) return null

    const workoutVisibility = currentSpace?.workoutVisibility
    const currentDate = DateTime.now().toMillis()
    const selectedDate = DateTime.fromFormat(activeDate, 'yyyy-LL-dd')

    switch (workoutVisibility) {
      case 'evening-before': {
        const revelationDate = selectedDate.minus({ days: 1 }).set({ hour: 18 })
        return currentDate < revelationDate.toMillis() ? (
          <>
            {getHumanReadableDate(revelationDate)} <> at&nbsp;6PM</>
          </>
        ) : null
      }
      case 'day-ahead': {
        const revelationDate = selectedDate.minus({ days: 1 }).set({ hour: 0 })
        return currentDate < revelationDate.toMillis() ? (
          <>{getHumanReadableDate(revelationDate)}</>
        ) : null
      }
      case 'week-ahead': {
        const revelationDate = selectedDate.minus({ days: 7 }).set({ hour: 0 })
        return currentDate < revelationDate.toMillis() ? (
          <>{getHumanReadableDate(revelationDate)}</>
        ) : null
      }
      default:
        return null
    }
  }

  const message = getRevelationMessage()

  const { draggedItem, tempSortedEvents, handleDragStart, handleDragEnd } =
    useCalendarEventSorter(currentDayEvents || [])

  const { updateWorkoutWithData } = useWorkoutBuilder({
    index: currentDayEvents?.length || 0,
    workoutEventToEdit: null,
    activeDate,
    onDismiss,
  })

  const { deleteWorkoutEvent } = useWorkoutPublisher()

  const { getMovementByID } = useMovements()
  const { addPredefinedWorkout } = useWorkoutPublisher()

  const sensors = useSensors(
    useSensor(MouseSensor, { activationConstraint: { distance: 10 } })
  )

  const handleEditWorkout = (eventToEdit: CalendarEvent.Workout) => {
    // When workout is "predefined", we actually make a variation of it
    // Workouts with scaling will only have default scaling

    const isPredefined = eventToEdit.details.isPredefined

    const eventData = isPredefined
      ? makeVariationOfPredefinedWorkoutEvent(eventToEdit)
      : eventToEdit

    setMode({ type: 'edit', data: eventData, index: eventData.index })
  }

  const addWorkoutByMovementID = (movementID: string) => {
    const index = currentDayEvents?.length || 0
    const movement = getMovementByID(movementID)
    const workoutEvent = getNewWorkoutEventWithMovement({
      gymID: currentSpace?.id || 'no-id',
      trackID: currentGymTrack || 'default',
      movement,
      userID,
      eventDateISO: activeDate || '',
      index,
    })
    handleEditWorkout(workoutEvent)
  }

  const handlePublishWorkout = (data: CalendarEvent.Workout) =>
    updateWorkoutWithData({
      newEventData: { ...data },
      newWorkoutData: data.details,
      preventDismiss: true,
      publishStatus: true,
    })

  const dismissWorkoutBuilder = () => {
    if (builderMode) onDismiss()
    setMode({ type: 'view' })
    setPreventActiveDateChange(false)
  }

  useEffect(() => {
    if (builderMode) setMode({ type: 'add-superset' })
  }, [isActive, builderMode])

  useEffect(() => {
    if (!builderMode) setMode({ type: 'view' })
    // scroll to top
    if (sidebarRef.current) sidebarRef.current.scrollTop = 0

    setScrollShadowIsVisible(false)
  }, [activeDate, builderMode])

  const [scrollShadowIsVisible, setScrollShadowIsVisible] = useState(false)

  const handleBuilderScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const scrollTop = (e.target as HTMLElement).scrollTop
    if (!scrollShadowIsVisible && scrollTop > 10) setScrollShadowIsVisible(true)
    else if (scrollShadowIsVisible && scrollTop <= 10)
      setScrollShadowIsVisible(false)
  }

  const [confirmation, setConfirmation] = useState<{
    title: string
    text: string
    what: string
    id: string
  } | null>(null)

  const handleDeleteConfirmation = (workoutEventID: string) => {
    const workoutEvent = currentDayEvents?.find(
      (event) => event.id === workoutEventID
    )
    if (workoutEvent) deleteWorkoutEvent(workoutEvent)
    setConfirmation(null)
  }

  const showDeleteConfirmation = (data: CalendarEvent.Workout) => {
    setConfirmation({
      title: 'Delete Workout?',
      text: 'This cannot be undone.',
      what: 'delete',
      id: data.id,
    })
    Analytics._logEvent({
      name: 'workout_deleted_via_quick_button',
      params: {
        workoutID: data.id,
        workoutTitle: data.details.title,
        workoutType: data.details.workoutType,
        workoutDate: activeDate,
      },
    })
  }

  const handleWorkoutAddViaInput = (shortcut: WorkoutShortcut) => {
    if (shortcut.type === 'strength') {
      Analytics._logEvent({
        name: 'workout_create_via_quick_button',
        params: {
          movementID: shortcut.movementIDs[0],
        },
      })
      addWorkoutByMovementID(shortcut.movementIDs[0])
    } else {
      Analytics._logEvent({
        name: 'add_benchmark_workout',
        params: {
          via: 'workout_add_input',
        },
      })
      if (!activeDate) console.error('No active date')
      else
        addPredefinedWorkout({
          workoutShortcut: shortcut,
          eventDateISO: activeDate,
          index: currentDayEvents?.length || 0,
        })
    }
  }

  const handleCardActon = (
    action: string,
    workoutEvent: CalendarEvent.Workout
  ) => {
    switch (action) {
      case 'edit':
        handleEditWorkout(workoutEvent)
        break
      case 'publish':
        handlePublishWorkout(workoutEvent)
        break
      case 'delete':
        showDeleteConfirmation(workoutEvent)
        break
    }
  }

  const getSidebarContent = () => {
    if (!activeDate && !builderMode) return <></>
    switch (mode.type) {
      case 'view': {
        if (currentDayEvents?.length)
          return (
            <div
              id={css['content']}
              onScroll={handleBuilderScroll}
              ref={sidebarRef}
            >
              <div className={css['events-container']}>
                <DndContext
                  sensors={sensors}
                  collisionDetection={closestCenter}
                  onDragStart={handleDragStart}
                  onDragEnd={handleDragEnd}
                  modifiers={[restrictToVerticalAxis, restrictToWindowEdges]}
                >
                  <SortableContext
                    items={currentDayEvents}
                    strategy={verticalListSortingStrategy}
                  >
                    {(tempSortedEvents || currentDayEvents).map(
                      (item, index) => (
                        <WorkoutCard
                          key={item.id}
                          id={item.id}
                          eventData={item}
                          workoutData={item.details}
                          style={{ marginBottom: '16px' }}
                          canSort={currentDayEvents.length > 1}
                          onAction={(action) => handleCardActon(action, item)}
                        />
                      )
                    )}
                  </SortableContext>

                  <DragOverlay>
                    {draggedItem ? (
                      <WorkoutCard
                        key={draggedItem.id}
                        id={draggedItem.id}
                        workoutData={draggedItem.details}
                        eventData={draggedItem}
                        canSort={currentDayEvents.length > 1}
                        onAction={() => {}}
                        isDragOverlay={true}
                      />
                    ) : null}
                  </DragOverlay>
                </DndContext>
              </div>
              {canEdit && (
                <>
                  <WorkoutAddInput onWorkoutAdd={handleWorkoutAddViaInput} />
                  <Button
                    onClick={() => setMode({ type: 'add-single' })}
                    style={{
                      marginTop: '16px',
                      paddingRight: '16px',
                    }}
                    type="secondary"
                    iconLeft={<PlusCircle size={16} color={'var(--accent)'} />}
                  >
                    Strength Workout
                  </Button>
                  <Button
                    onClick={() => setMode({ type: 'add-superset' })}
                    style={{
                      marginTop: '8px',
                      paddingRight: '16px',
                    }}
                    type="secondary"
                    iconLeft={<PlusCircle size={16} color={'var(--accent)'} />}
                  >
                    Conditioning Workout
                  </Button>
                </>
              )}
            </div>
          )
        else
          return canEdit ? (
            <div id={`${css['content']}`} className={`${css['only-buttons']}`}>
              <WorkoutAddInput onWorkoutAdd={handleWorkoutAddViaInput} />
              <ButtonArea
                icon={<IconNewWorkout type="single" color="#b7b7b7" />}
                iconOnHover={
                  <IconNewWorkout type="single" color="var(--accent)" />
                }
                onClick={() => setMode({ type: 'add-single' })}
              >
                Strength Workout
              </ButtonArea>
              <ButtonArea
                icon={<IconNewWorkout type="superset" color="#b7b7b7" />}
                iconOnHover={
                  <IconNewWorkout type="superset" color="var(--accent)" />
                }
                onClick={() => setMode({ type: 'add-superset' })}
              >
                Conditioning Workout
              </ButtonArea>
            </div>
          ) : (
            <div className={css['placeholder']}>No workouts posted</div>
          )
      }

      case 'add-single': {
        return (
          <WorkoutBuilder
            newWorkoutType="single"
            index={
              editExercise ? editExercise.index : currentDayEvents?.length || 0
            }
            workoutEventToEdit={null}
            dateSubheader={
              <DateSubheader
                fullDate={fullDate}
                activeDate={activeDate || ''}
                hideMessage={message}
              />
            }
            onDismiss={dismissWorkoutBuilder}
            activeDate={activeDate}
            isPresetMode={builderMode}
          />
        )
      }
      case 'add-superset': {
        return (
          <WorkoutBuilder
            newWorkoutType="superset"
            index={
              editExercise ? editExercise.index : currentDayEvents?.length || 0
            }
            workoutEventToEdit={null}
            dateSubheader={
              <DateSubheader
                fullDate={fullDate}
                activeDate={activeDate || ''}
                hideMessage={message}
              />
            }
            onDismiss={dismissWorkoutBuilder}
            activeDate={activeDate}
            isPresetMode={builderMode}
          />
        )
      }

      case 'edit': {
        return (
          <WorkoutBuilder
            workoutToEdit={mode.data?.details}
            index={
              mode.data
                ? mode.data.index
                : currentDayEvents
                ? currentDayEvents.length
                : 0
            }
            workoutEventToEdit={mode.data || null}
            dateSubheader={
              <DateSubheader
                fullDate={fullDate}
                activeDate={activeDate || ''}
                hideMessage={message}
              />
            }
            onDismiss={dismissWorkoutBuilder}
            activeDate={activeDate}
          />
        )
      }
    }
  }

  return (
    <Sidebar isActive={isActive} onDismiss={onDismiss} blankTemplate={true}>
      {/* <div
        id={css['sidebar']}
        className={`${isActive && css['active']} ${css[mode.type]}`}
      > */}
      <Tooltip
        id="t-drag-hint-card"
        place="left-start"
        delayShow={400}
        className="tooltip"
        noArrow={true}
        offset={4}
        style={{ margin: '-4px 0 0 0' }}
      />

      <Confirm
        isVisible={!!confirmation}
        title={confirmation ? confirmation.title : ''}
        text={confirmation ? confirmation.text : ''}
        buttonLabels={
          confirmation && confirmation.what === 'delete'
            ? { primary: 'Delete', secondary: 'Go Back' }
            : { primary: 'Proceed', secondary: 'Go Back' }
        }
        onDismiss={() => setConfirmation(null)}
        onConfirm={() => {
          if (confirmation?.what === 'delete')
            return handleDeleteConfirmation(confirmation.id)
        }}
        isDestructive={confirmation?.what === 'delete'}
      />

      {mode.type === 'view' ? (
        <header>
          <div className={`${css['subheader']} ${'light'}`}>
            <DateSubheader
              fullDate={fullDate}
              activeDate={activeDate || ''}
              hideMessage={message}
            />
          </div>

          <h2>{dayOfWeek}</h2>

          <ButtonIcon
            icon={<IconClose />}
            className={css['close-icon']}
            onClick={onDismiss}
            type={'light'}
            style={{ margin: '-4px 0 0 0' }}
          />
          <div
            className={`${css['scroll-shadow']} ${
              scrollShadowIsVisible && css['visible']
            }`}
          />
        </header>
      ) : (
        ''
      )}

      {getSidebarContent()}
      {/* </div> */}
    </Sidebar>
  )
}

export default CalendarSidebar

const getHumanReadableDate = (date: DateTime) => {
  // if date is today, return 'today'
  // if tomorrow, return 'tomorrow'
  // if the date falls within the current week, return weekday name
  // otherwise return in human-readable format

  const today = DateTime.now().set({ hour: 0, minute: 0, second: 0 })
  const tomorrow = today.plus({ days: 1 })

  if (date.toFormat('yyyy-LL-dd') === today.toFormat('yyyy-LL-dd'))
    return 'today'
  else if (date.toFormat('yyyy-LL-dd') === tomorrow.toFormat('yyyy-LL-dd'))
    return 'tomorrow'
  else if (today.hasSame(date, 'week')) {
    return `this ${date.toFormat('EEEE')}` // Return the weekday name
  } else
    return `on ${date.toLocaleString({
      month: 'short',
      day: 'numeric',
    })}`
}
