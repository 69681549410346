import { DateTime } from 'luxon'
import React, { useContext, useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { AppContext } from '../../context/AppContext'
import { GymMemberData, GymTrack } from '../../types/types'
import Button from '../buttons/Button/Button'
import useGym from '../../hooks/useGym'
import SegmentedInput from '../inputs/SegmentedInput/SegmentedInput'
import ToggleInput from '../inputs/ToggleInput/ToggleInput'
import Avatar from '../atoms/Avatar/Avatar'
import Modal from './Modal'
import { v4 as uuid } from 'uuid'
import MultiSelect from '../inputs/MultiSelect/MultiSelect'
import usePeopleManagement from '../../hooks/usePeopleManagement'
import { set } from 'lodash'

type Props = {
  isVisible: boolean
  isLoading?: boolean
  onCancel: () => void
  onConfirm: () => void
  editGymTrack: GymTrack | null
}

const GymTrackModal = ({
  editGymTrack,
  isVisible,
  onCancel,
  onConfirm,
}: Props) => {
  const { currentSpace } = useContext(AppContext)

  const formMethods = useForm<GymTrack>({
    defaultValues: editGymTrack || undefined,
  })
  const {
    register,
    reset,
    handleSubmit,
    setFocus,
    setError,
    clearErrors,
    formState: { errors: formErrors },
  } = formMethods
  const { isLoading, updateGymTrack } = useGym({
    onComplete: () => {
      reset()
      onConfirm()
    },
    joinMode: false,
  })

  const save = (data: GymTrack) => {
    console.log('data', data)

    const gymTrack: GymTrack = {
      id: editGymTrack ? editGymTrack.id : 'track_' + uuid().substring(0, 8),
      name: data.name.trim(),
      isDeleted: false,
      allowList: data.allowList?.length ? data.allowList : null,
    }

    // Check if name is already exists
    const trackNameExists = currentSpace?.gymTracks
      ?.filter((track) => !track.isDeleted && track.id !== gymTrack.id)
      .find(
        (track) =>
          track.name.trim().toLowerCase() === data.name.trim().toLowerCase()
      )
    if (trackNameExists) {
      console.log('trackNameExists', trackNameExists)
      setError('name', {
        type: 'manual',
        message: 'Track with this name already exists',
      })
      setFocus('name')
      return
    }

    //Check if limited access is set but no members are selected
    if (limitAccess && (!data.allowList || data.allowList.length === 0)) {
      setError('allowList', {
        type: 'manual',
        message: 'Please add at least one member',
      })
      setFocus('allowList')
      return
    }

    console.log('formErrors', formErrors)

    updateGymTrack(gymTrack)
  }

  useEffect(() => setFocus('name'), [isVisible])

  console.log('editGymTrack', editGymTrack)

  const [limitAccess, setLimitAccess] = useState(
    editGymTrack?.allowList ? true : false
  )
  const [tempAllowList, setTempAllowList] = useState<string[]>(
    editGymTrack?.allowList || []
  )

  const handlePrivateToggle = () => {
    if (!limitAccess) formMethods.setValue('allowList', tempAllowList)
    else formMethods.setValue('allowList', null)
    setLimitAccess(!limitAccess)
  }

  useEffect(() => {
    console.log('tempAllowList', tempAllowList)
  }, [tempAllowList])

  const { members } = usePeopleManagement({
    currentSpace: currentSpace?.id || null,
    onUpdateFailure: () => {},
    onUpdateSuccess: () => {},
  })

  const membersList = (members as GymMemberData[])
    .filter((m) => !!m.name)
    .map((member) => ({
      id: member.uid,
      name: member.name as string,
    }))

  const allowList = formMethods.watch('allowList') || []

  return (
    <Modal
      isVisible={isVisible}
      onCancel={() => {
        reset()
        onCancel()
      }}
      size="small"
      title={editGymTrack ? 'Edit Gym Track' : 'Add Gym Track'}
      buttons={{
        secondary: {
          onClick: () => {
            reset()
            onCancel()
          },
        },
        primary: {
          onClick: handleSubmit(save),
          label: 'save',
          isSubmitButton: true,
          disabled: formErrors.root ? true : false,
          isLoading: isLoading,
        },
      }}
    >
      <form onSubmit={handleSubmit(save)}>
        <label>Track Name</label>
        <input
          defaultValue={editGymTrack ? editGymTrack.name : ''}
          maxLength={24}
          style={{
            borderColor: formErrors.name ? 'var(--accent-red)' : '',
          }}
          {...register('name', {
            //onChange: () => setError(null),
            required: 'Please enter track name',
          })}
        />
        {formErrors.name && (
          <p
            style={{
              marginTop: '4px',
              color: 'var(--accent-red)',
              font: 'var(--h6)',
            }}
          >
            {formErrors.name.message?.toString()}
          </p>
        )}
        <div
          style={{
            border: '1px dotted var(--divider-solid)',
            padding: '16px',
            borderRadius: '4px',
            margin: '16px 0 24px',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <ToggleInput
              isActive={limitAccess}
              label="Limit Access"
              onToggle={handlePrivateToggle}
              style={{ marginTop: '0px' }}
              isDisabled={editGymTrack?.id === 'default'}
            />
            {editGymTrack?.id === 'default' && (
              <span
                style={{
                  marginTop: '4px',
                  font: 'var(--h6)',
                  fontWeight: 400,
                  color: 'var(--secondary-text)',
                }}
              >
                The primary track cannot be private
              </span>
            )}
          </div>
          {limitAccess && (
            <div>
              <label>Allowed Members</label>
              <FormProvider {...formMethods}>
                <MultiSelect
                  items={membersList}
                  onSelectedItemsChange={(items) => {
                    const allowList = items.map((item) => item.id)
                    setTempAllowList(allowList)
                    formMethods.setValue('allowList', allowList)
                    clearErrors('allowList')
                  }}
                  selectedItems={
                    allowList
                      .map((id: string) => membersList.find((m) => m.id === id))
                      .filter((item) => item !== undefined) as {
                      id: string
                      name: string
                    }[]
                  }
                  isError={!!formErrors.allowList}
                />
              </FormProvider>

              {!formErrors.allowList ? (
                <span
                  style={{
                    marginTop: '4px',
                    font: 'var(--h6)',
                    fontWeight: 400,
                    color: 'var(--secondary-text)',
                  }}
                >
                  Gym staff have access to all tracks
                </span>
              ) : (
                <span
                  style={{
                    marginTop: '4px',
                    color: 'var(--accent-red)',
                    font: 'var(--h6)',
                  }}
                >
                  {formErrors.allowList.message}
                </span>
              )}
            </div>
          )}
        </div>
      </form>
    </Modal>
  )
}

export default GymTrackModal
