import React, { useContext, useEffect, useState } from 'react'

import { Routes, Route, Navigate, useLocation } from 'react-router-dom'
import posthog from 'posthog-js'

import Layout from './Layout'
import CalendarPage from '../CalendarPage'
import Members from '../Members'
import Loading from '../Loading'
import { useAuthState } from 'react-firebase-hooks/auth'

import { auth, logout } from '../../firebase'
import { AppContext } from '../../context/AppContext'
import { UserContext } from '../../context/UserContext'
import Onboarding from '../onboarding/Onboarding'
import SystemMessage from './SystemMessage/SystemMessage'
import { GymData, UserData } from '../../types/types'
import PrintCode from './PrintCode/PrintCode'
import GymSettings from '../GymSettings/GymSettings'
import Account from '../Account'
import { subAllowsAccess } from '../../helpers_ts'
import GetApp from './GetApp'
import Support from './Support'
import { CalendarContextProvider } from '../../context/CalendarContext'
import GymTV from '../GymTV/GymTV'
import Logout from '../onboarding/auth/Logout'
import Playbook from '../Playbook'
import { Success } from '../onboarding/auth/Success'
type Props = {}

const Router = (props: Props) => {
  const { user, userID, userIsLoading } = useContext(UserContext)
  const { currentSpace, appIsLoading } = useContext(AppContext)

  const metaTheme = document.querySelector('meta[name=theme-color]')
  const themeColor = !user ? 'rgb(35,35,35)' : '#333'
  metaTheme?.setAttribute('content', themeColor)

  const windowLocation = window.location.href
  const params = new URLSearchParams(windowLocation)

  const modeViaParams = params.get('mode')

  const location = useLocation()

  useEffect(() => {
    posthog.capture('$pageview')
  }, [location])

  const getRoutes = () => {
    const access = getAccess(user, userID, currentSpace, modeViaParams)

    switch (access) {
      case 'access-allowed':
        return (
          <Route
            path="/"
            element={
              <Layout
                incomplete={['incomplete', 'past_due'].includes(
                  currentSpace?.subscription?.status || ''
                )}
              />
            }
          >
            <Route path="/printCode/:code" element={<PrintCode />} />
            <Route path="/" element={<CalendarPage />} />
            <Route path="/playbook" element={<Playbook />} />
            <Route path="/members" element={<Members />} />
            <Route path="/account" element={<Account />} />
            <Route path="/tv" element={<GymTV />} />
          </Route>
        )

      case 'access-allowed-owner':
        return (
          <Route
            path="/"
            element={
              <Layout
                incomplete={['incomplete', 'past_due'].includes(
                  currentSpace?.subscription?.status || ''
                )}
              />
            }
          >
            <Route path="/printCode/:code" element={<PrintCode />} />
            <Route path="/" element={<CalendarPage />} />
            <Route path="/playbook" element={<Playbook />} />
            <Route path="/members" element={<Members />} />
            <Route path="/gym-settings" element={<GymSettings />} />
            <Route path="/account" element={<Account />} />
            <Route path="/tv" element={<GymTV />} />
          </Route>
        )

      case 'subscription-owner-canceled':
        return (
          <Route path="/" element={<Layout limitedAccess={true} />}>
            <Route path="/account" element={<Account />} />
            <Route
              path="/gym-settings"
              element={<GymSettings limitedAccess={true} />}
            />
            <Route path="/" element={<Navigate to="/gym-settings" replace />} />
            <Route
              path="/*"
              element={<Navigate to="/gym-settings" replace />}
            />
          </Route>
        )
      case 'access-pending':
      case 'access-denied':
      case 'subscription-inactive':
      case 'failed-to-fetch-subscription':
        return <Route path="/" element={<SystemMessage type={access} />} />

      case 'reverify-email':
        return (
          <>
            <Route path="/" element={<SystemMessage type={access} />} />
            <Route
              path="/__/auth/:action"
              element={<SystemMessage type={access} />}
            />
          </>
        )

      case 'subscription-not-selected':
      case 'onboarding-pending':
      case 'reset-password':
      default:
        return (
          <>
            <Route
              //__/auth/action?&mode=action&oobCode=code
              path="/__/auth/:action"
              element={<Onboarding />}
            />
            <Route path="/" element={<Onboarding />} />
          </>
        )
    }
  }

  return userIsLoading || appIsLoading ? (
    <Loading />
  ) : (
    <Routes>
      {getRoutes()}
      <Route path="/get/:code" element={<GetApp />} />
      <Route path="/get" element={<GetApp />} />
      <Route path="/support" element={<Support />} />
      <Route path="/logout" element={<Logout />} />
      <Route path="/success" element={<Success />} />
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  )
}

export default Router

const getAccess = (
  user: UserData,
  userID: string,
  currentSpace: GymData | null,
  modeViaParams: string | null
) => {
  // Access only allowed for staff or gym owner

  const isOwnerOrSupport =
    currentSpace?.ownerID === userID || currentSpace?.role === 'support'

  if (modeViaParams === 'resetPassword') return 'reset-password'

  // Onboarding not complete
  if (!user || !user.introComplete || !currentSpace) return 'onboarding-pending'
  if (user && modeViaParams === 'recoverEmail') return 'recover-email'

  // Email changed and needs verification
  if (user && user.introComplete && !auth.currentUser?.emailVerified)
    return 'reverify-email'

  if (!currentSpace.subscription) {
    return 'failed-to-fetch-subscription' // probably network error
  }

  // Subscription was not selected during onboarding for some reason
  if (currentSpace.subscription.status === 'does-not-exist') {
    return currentSpace.ownerID !== userID
      ? 'subscription-inactive'
      : 'subscription-not-selected'
  }

  // Owner view: subscription cancelled
  if (!subAllowsAccess(currentSpace.subscription.status))
    return isOwnerOrSupport
      ? 'subscription-owner-canceled'
      : 'subscription-inactive'

  // Subscription is good
  if (subAllowsAccess(currentSpace.subscription.status)) {
    // The user is a staff member
    if (
      isOwnerOrSupport ||
      (currentSpace.permissions &&
        (currentSpace.permissions.canEditMembers ||
          currentSpace.permissions.canEditPermissions ||
          currentSpace.permissions.canEditWorkouts ||
          ['coach', 'manager'].includes(currentSpace.role)))
    )
      return isOwnerOrSupport ? 'access-allowed-owner' : 'access-allowed'
  }

  if (!currentSpace.hasAccess && currentSpace.ownerID !== userID)
    return 'access-denied'
  else return 'access-pending'
}
