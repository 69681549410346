import React, { ReactNode } from 'react'
import Footer from '../../components/sections/Footer/Footer'
import SecondaryNav from '../../components/navigation/SecondaryNavigation/SecondaryNav'

type Props = {
  isLoading?: boolean
  onChange?: (params: any) => void
  children: ReactNode
  data?: any
  onScroll?: (params: any) => void
  forwardRef?: any
}

const Page = ({
  isLoading,
  onChange,
  children,
  data,
  onScroll,
  forwardRef,
}: Props) => {
  return (
    <>
      {/* <SecondaryNav isLoading={isLoading} onChange={onChange} data={data} /> */}
      <div id="container" onScroll={onScroll} ref={forwardRef}>
        {children}
        <Footer />
      </div>
    </>
  )
}

export default Page
