import React, {
  useEffect,
  useContext,
  useRef,
  useState,
  createRef,
  RefObject,
  useCallback,
} from 'react'
import Day from '../Day/Day'
import classNames from 'classnames/bind'
import styles from './Month.module.css'

import { isToday } from '../../../../helpers.js'
import { CalendarContext } from '../../../../context/CalendarContext'
import { AppContext, CalendarViewState } from '../../../../context/AppContext'
import Month from './Month'
import _, { get, set } from 'lodash'
import { useResizeObserver } from 'usehooks-ts'
import { DndContext, closestCorners, DragOverlay } from '@dnd-kit/core'
import WorkoutItem from '../WorkoutItem/WorkoutItem'
import useCalendarEventDragNDropper from '../../../../hooks/calendar/useCalendarEventDragNDropper'
import useCalendarInfiniteScroller from '../../../../hooks/calendar/useCalendarInfiniteScroller'
import { Tool } from 'react-feather'
import { Tooltip } from 'react-tooltip'
import { usePageContext } from '../../../../context/PageContext'

interface Props {
  isSidebarActive: boolean
  onDayClick: (clickedDate: string) => void
  /** format: YYYY-MM */
  activeYearMonth: string
}

const cx = classNames.bind(styles)

const Calendar = ({ activeYearMonth, isSidebarActive, onDayClick }: Props) => {
  const ref = useRef<HTMLDivElement>(null)
  const sundayIsFirst = false
  const weekdayTitles = sundayIsFirst
    ? ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
    : ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']

  const { calendarViewState } = useContext(AppContext)
  const { setLoading } = usePageContext()

  const {
    isLoading,
    monthsReducer: { months },
    activeDate,
    isScrolling,
  } = useContext(CalendarContext)

  const {
    refs: { calendarRef, containerRef, monthsRef, monthRefs, anchorRef },
    handleIntersection,
    handleScroll,
    handleWheel,
  } = useCalendarInfiniteScroller()

  const {
    dndSensors,
    dragOperation,
    isDuplicating,
    handleDragStart,
    handleDragOver,
    handleDragEnd,
    handleDragCancel,
  } = useCalendarEventDragNDropper({})

  return (
    <div
      id={cx('calendar')}
      className={`${isSidebarActive && cx('sidebar')}`}
      ref={calendarRef}
    >
      <div
        className={cx('month-and-weekdays-container', {
          'is-expanded': calendarViewState === CalendarViewState.Expand,
        })}
      >
        <div id={cx('weekdays')}>
          {weekdayTitles.map((weekdayTitle) => (
            <div className={cx('weekday')} key={`${weekdayTitle}`}>
              {weekdayTitle}
            </div>
          ))}
        </div>

        <div
          className={cx('months-scroll')}
          ref={containerRef}
          onScroll={handleScroll}
          onWheel={handleWheel}
          style={{
            position: 'relative',
          }}
        >
          <div
            ref={anchorRef}
            style={{
              position: 'absolute',
              left: 0,
              width: '100%',
              opacity: 0,
              backgroundColor: 'red',
              zIndex: 999999,
              pointerEvents: 'none',
            }}
          />
          <DndContext
            onDragStart={handleDragStart}
            onDragOver={handleDragOver}
            onDragEnd={handleDragEnd}
            collisionDetection={closestCorners}
            onDragCancel={handleDragCancel}
            sensors={dndSensors}
          >
            <DragOverlay>
              {dragOperation ? (
                <WorkoutItem
                  item={dragOperation.draggedEvent}
                  hovering
                  duplicating={isDuplicating}
                />
              ) : null}
            </DragOverlay>
            <div className={cx('months')} ref={monthsRef}>
              {months.map((month, index) => (
                <Month
                  key={month.toFormat('yyyy-MM')}
                  month={month}
                  onDayClick={onDayClick}
                  activeDate={activeDate}
                  isToday={isToday}
                  sundayIsFirst={sundayIsFirst}
                  forwardRef={monthRefs.current[index]}
                  isScrolling={isScrolling}
                  isExpanded={calendarViewState === CalendarViewState.Expand}
                  onIntersectionStateChange={(inView, entry) => {
                    handleIntersection(entry, index)
                  }}
                  showMonthName={isScrolling || !!dragOperation}
                />
              ))}
            </div>
          </DndContext>
        </div>
      </div>
      <Tooltip
        id={'tooltip-drag-hint'}
        place="bottom-end"
        delayShow={600}
        hidden={!!dragOperation}
        offset={4}
        className="tooltip small"
        noArrow={true}
        children={<span>Drag to rearrange</span>}
      />
    </div>
  )
}

export default Calendar
