import React, { createContext, useContext, useReducer, ReactNode } from 'react'
import { PlaybookActionPayload, PlaybookPageState } from '../pages/Playbook'

type Page = 'calendar' | 'playbook' | 'members' | 'gym-settings' | 'account'
// Define types
export type BasePageState = {
  isLoading: boolean
  page: Page
}

type ActionPayload = { page: 'unset' } | PlaybookActionPayload
type PageState = BasePageState | PlaybookPageState

type Action =
  | { type: 'SET_LOADING'; payload: boolean }
  | { type: 'DISPATCH_ACTION'; payload: ActionPayload }

type PageContextType = {
  state: PageState
  setLoading: (isLoading: boolean) => void
  dispatchAction: (action: ActionPayload) => void
}

// Create the context
const PageContext = createContext<PageContextType | undefined>(undefined)

// Action types
const SET_LOADING = 'SET_LOADING'
const DISPATCH_ACTION = 'DISPATCH_ACTION'

// Reducer function
const pageReducer = (state: PageState, action: Action): PageState => {
  switch (action.type) {
    case SET_LOADING:
      return { ...state, isLoading: action.payload }
    case DISPATCH_ACTION:
      switch (action.payload.page) {
        case 'playbook':
          return {
            ...state,
            ...(action.payload.setCategory
              ? { activeCategory: action.payload.setCategory }
              : {}),
            page: action.payload.page,
            action: action.payload.action,
          }
      }
      return state
    default:
      return state
  }
}

// Provider component
export const PageContextProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(pageReducer, {
    isLoading: false,
    page: 'calendar',
  })

  const setLoading = (isLoading: boolean) => {
    dispatch({ type: SET_LOADING, payload: isLoading })
  }

  const dispatchAction = (payload: ActionPayload) => {
    dispatch({
      type: DISPATCH_ACTION,
      payload,
    })
  }

  return (
    <PageContext.Provider value={{ state, setLoading, dispatchAction }}>
      {children}
    </PageContext.Provider>
  )
}

// Custom hook to use the loading context
export const usePageContext = (): PageContextType => {
  const context = useContext(PageContext)

  if (!context) {
    throw new Error('useLoading must be used within a LoadingProvider')
  } else {
    //context.setPage(page)
    return context
  }
}
