import React, { useContext } from 'react'
import { AppContext } from '../context/AppContext'
import { GymTrack } from '../types/types'

type Props = {}

/**
 *
 * @returns Array of current gym tracks
 */
const useGymTracks = () => {
  const { currentSpace } = useContext(AppContext)

  // Legacy gyms have gymTracks undefined
  const defaultTrack: GymTrack = {
    id: 'default',
    name: 'Workout of the Day',
    isDeleted: false,
    allowList: null,
  }

  // Track with id 'default' always comes first

  return (
    currentSpace?.gymTracks
      ?.filter((track) => !track.isDeleted)
      .sort((a, b) =>
        a.id === 'default' ? -1 : b.id === 'default' ? 1 : 0
      ) || [defaultTrack]
  )
}

export default useGymTracks
