import CycleCard from '../../cards/CycleCard/CycleCard'
import Sidebar from './Sidebar'
import css from './Sidebar.module.css'
import ButtonToolbar from '../../buttons/ButtonToolbar/ButtonToolbar'
import { Calendar, Plus, PlusCircle } from 'react-feather'
import { Cycle } from '../../../types/cycles'
import { DateTime } from 'luxon'
import Button from '../../buttons/Button/Button'
import { useContext, useState } from 'react'
import CycleBuilder from '../../forms/CycleBuilder/CycleBuilder'
import useCycles from '../../../hooks/cycles/useCycles'
import useCyclePublisher from '../../../hooks/cycles/useCyclePublisher'
import { Analytics } from '../../../services/analytics'
import Confirm from '../../modals/Confirm'
import ButtonArea from '../../buttons/ButtonArea/ButtonArea'

import { UserContext } from '../../../context/UserContext'
import { AppContext } from '../../../context/AppContext'

type Props = {
  isActive: boolean
  onDismiss: () => void
}

const CyclesSidebar = ({ isActive, onDismiss }: Props) => {
  const [mode, setMode] = useState<'view' | 'edit'>('view')

  const [editCycle, setEditCycle] = useState<Cycle | null>(null)

  const { cycles, activeCycle, pastCycles, futureCycles } = useCycles()

  const { deleteCycle } = useCyclePublisher()

  const { currentSpace } = useContext(AppContext)
  const { userID } = useContext(UserContext)
  const canEdit =
    currentSpace?.permissions.canEditWorkouts ||
    currentSpace?.ownerID === userID

  const [confirmation, setConfirmation] = useState<{
    text: string
    title: string
    what: string
    data: Cycle
  } | null>(null)

  const handleDeleteWorkout = (data: Cycle) => {
    setConfirmation({
      title: 'Delete Cycle?',
      text: 'This cannot be undone.',
      what: 'delete',
      data: data,
    })
    if (data)
      Analytics._logEvent({
        name: 'cycle_deleted_via_quick_button',
        params: {
          cycleID: data.id,
          cycleTitle: data.title,
        },
      })
  }

  const handleDeleteConfirmation = (data: Cycle) => {
    deleteCycle(data)
    setConfirmation(null)
  }

  const handleAction = (
    action: 'new' | 'edit' | 'delete',
    cycle: Cycle | null
  ) => {
    switch (action) {
      case 'new':
        setMode('edit')
        setEditCycle(null)
        break
      case 'edit':
        setMode('edit')
        setEditCycle(cycle)
        break
      case 'delete':
        if (cycle) handleDeleteWorkout(cycle)
    }
  }

  // Rendering Content
  if (mode === 'view')
    return (
      <Sidebar
        isActive={isActive}
        onDismiss={onDismiss}
        header="Workout Cycles"
      >
        <Confirm
          isVisible={!!confirmation}
          text={confirmation ? confirmation.text : ''}
          title={confirmation ? confirmation.title : ''}
          buttonLabels={
            confirmation && confirmation.what === 'delete'
              ? { primary: 'Delete', secondary: 'Go Back' }
              : { primary: 'Proceed', secondary: 'Go Back' }
          }
          onDismiss={() => setConfirmation(null)}
          onConfirm={() => {
            if (confirmation?.what === 'delete')
              return handleDeleteConfirmation(confirmation.data)
          }}
          isDestructive={confirmation?.what === 'delete'}
        />
        <div
          // style={{ borderTop: 'solid 0.5px var(--divider)', paddingTop: '16px' }}
          style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}
          className={css['cycles-sidebar']}
        >
          {!cycles ? (
            canEdit ? (
              <ButtonArea
                onClick={() => setMode('edit')}
                icon={
                  <Calendar size={80} color={'var(--icon)'} strokeWidth={1.2} />
                }
                iconOnHover={
                  <Calendar
                    size={80}
                    color={'var(--accent)'}
                    strokeWidth={1.2}
                  />
                }
              >
                <div
                  style={{
                    width: '65%',
                    margin: '0 auto',
                    textAlign: 'center',
                  }}
                >
                  <div
                    style={{
                      marginTop: '8px',
                    }}
                  >
                    Add New Cycle
                  </div>
                  <div
                    style={{
                      fontSize: '14px',
                      fontWeight: '400',
                      margin: '12px 0 24px',
                    }}
                  >
                    Workout cycles help you keep the&nbsp;calendar organized and
                    give more details about your programming to&nbsp;athletes
                  </div>
                </div>
              </ButtonArea>
            ) : (
              <div className={css['placeholder']}>No cycles added</div>
            )
          ) : (
            <>
              <section>
                {activeCycle && (
                  <CycleCard
                    data={activeCycle}
                    onAction={(action) => handleAction(action, activeCycle)}
                  />
                )}
                {canEdit && (
                  <Button
                    onClick={() => handleAction('new', null)}
                    style={{
                      paddingRight: '16px',
                    }}
                    type="secondary"
                    iconLeft={<PlusCircle size={16} color={'var(--accent)'} />}
                  >
                    New Cycle
                  </Button>
                )}
              </section>
              {!!futureCycles && !!futureCycles.length && (
                <section>
                  <h6>Upcoming</h6>
                  {futureCycles.map((cycle) => (
                    <CycleCard
                      key={cycle.id}
                      data={cycle}
                      onAction={(action) => handleAction(action, cycle)}
                    />
                  ))}
                </section>
              )}
              {!!pastCycles && !!pastCycles.length && (
                <section>
                  <h6>Past</h6>
                  {pastCycles.map((cycle) => (
                    <CycleCard
                      key={cycle.id}
                      data={cycle}
                      onAction={(action) => handleAction(action, cycle)}
                    />
                  ))}
                </section>
              )}
            </>
          )}
        </div>
      </Sidebar>
    )
  else
    return (
      <Sidebar isActive={isActive} onDismiss={onDismiss} blankTemplate={true}>
        <CycleBuilder
          cycleToEdit={editCycle}
          onDismiss={() => setMode('view')}
        />
      </Sidebar>
    )
}

export default CyclesSidebar
