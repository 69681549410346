import posthog from 'posthog-js'
import ButtonToolbar from '../../../buttons/ButtonToolbar/ButtonToolbar'
import css from '../SecondaryNav.module.css'
import { useContext, useEffect } from 'react'
import { Plus, Send, Share, Tv } from 'react-feather'
import SegmentedInput from '../../../inputs/SegmentedInput/SegmentedInput'
import { Workout } from '../../../../types/workouts'
import { translateWorkoutType } from '../../../../helpers_ts'
import { config } from '../../../../config'
import { UserContext } from '../../../../context/UserContext'
import { usePageContext } from '../../../../context/PageContext'
import { PlaybookPageState } from '../../../../pages/Playbook'

type Props = {}

const PlaybookNav = () => {
  const { userID } = useContext(UserContext)
  const { state, dispatchAction } = usePageContext()

  const playbookCategories: (Workout.Item['workoutType'] | 'everything')[] = [
    'girls',
    'heroes',
    'special',
    'cf-open',
    'gymnastics',
    'endurance',
    'everything',
  ]

  const { access } = config

  const isFeatureEnabled = posthog.isFeatureEnabled('playbook_add_new_workout')

  const isAddNewWorkoutEnabled =
    access.playbook_add_new_workout?.includes(userID)

  return (
    <>
      <h2>Playbook</h2>

      <div className={css['button-group']}>
        <SegmentedInput
          segments={
            playbookCategories.map((category) => ({
              label: translateWorkoutType(category),
              value: category,
            })) || []
          }
          value={
            state.page === 'playbook'
              ? (state as PlaybookPageState).activeCategory
              : 'girls'
          }
          dark
          style={{
            height: '32px',
            width: '600px',
          }}
          onChange={(value) =>
            dispatchAction({ page: 'playbook', setCategory: value })
          }
        />
        {(!!isFeatureEnabled || isAddNewWorkoutEnabled) && (
          <ButtonToolbar
            icon={<Plus color="var(--accent)" size={16} />}
            onClick={() =>
              dispatchAction({ page: 'playbook', action: 'new-workout' })
            }
          >
            New Workout
          </ButtonToolbar>
        )}
      </div>
    </>
  )
}

export default PlaybookNav
