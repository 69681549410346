import { useContext, useState } from 'react'
import { AlertOctagon, AlertTriangle, User } from 'react-feather'
import { Outlet, useLocation } from 'react-router-dom'
import AccessCode from '../../components/modals/AccessCode'
import Navbar from '../../components/navigation/MainNavigation/MainNavigation'
import { AppContext } from '../../context/AppContext'
import { UserContext } from '../../context/UserContext'

import { manageSubscription } from '../../services/manageSubscription'
import ErrorStripe from '../../components/atoms/ErrorStripe/ErrorStripe'
import SecondaryNav from '../../components/navigation/SecondaryNavigation/SecondaryNav'
import { PageContextProvider } from '../../context/PageContext'
import { CalendarContextProvider } from '../../context/CalendarContext'

type Props = {
  limitedAccess?: boolean
  incomplete?: boolean
}

const Layout = ({ limitedAccess, incomplete }: Props) => {
  const { userID } = useContext(UserContext)
  const { currentSpace } = useContext(AppContext)
  const [modalIsVisible, setModalIsVisible] = useState(false)

  const location = useLocation()
  const path = location.pathname
  const noNavBarLocations = ['/printCode', '/tv']

  const enableMainNavigation = !noNavBarLocations.some((loc) =>
    path.includes(loc)
  )

  return (
    <PageContextProvider>
      <CalendarContextProvider>
        <div
          style={{
            minWidth: '960px',
            display: 'flex',
            flexDirection: 'column',
            height: '100vh',
          }}
          className={incomplete && enableMainNavigation ? 'top-error' : ''}
        >
          {enableMainNavigation && (
            <div id="header">
              {incomplete && (
                <ErrorStripe>
                  {currentSpace?.ownerID === userID ? (
                    <>
                      We couldn't process your payment. Please{' '}
                      <span
                        onClick={() =>
                          manageSubscription(currentSpace?.subscription)
                        }
                        className="link"
                      >
                        check your payment details
                      </span>{' '}
                      to retain access.
                    </>
                  ) : (
                    <>
                      There was an issue with Rewod subscription payment. Please
                      notify the {currentSpace?.title} owner about this.
                    </>
                  )}
                </ErrorStripe>
              )}
              <Navbar limitedAccess={limitedAccess} />
              <SecondaryNav isLoading={false} />
            </div>
          )}
          <div id="outlet">
            <Outlet />
          </div>
          {!currentSpace?.accessCode &&
            !location.pathname.includes('/printCode') && (
              <AccessCode
                isVisible={true}
                onDismiss={() => setModalIsVisible(false)}
              />
            )}
        </div>
      </CalendarContextProvider>
    </PageContextProvider>
  )
}

export default Layout
