import { DateTime } from 'luxon'
import React, { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { AppContext } from '../../context/AppContext'
import { GymMemberData } from '../../types/types'
import Button from '../buttons/Button/Button'
import useGym from '../../hooks/useGym'
import SegmentedInput from '../inputs/SegmentedInput/SegmentedInput'
import ToggleInput from '../inputs/ToggleInput/ToggleInput'
import Avatar from '../atoms/Avatar/Avatar'
import Modal from './Modal'
import { translateGymData } from '../../helpers_ts'

type Props = {
  isVisible: boolean
  isLoading?: boolean
  onCancel: () => void
  onConfirm: () => void
}

const WorkoutVisibility = ({ isVisible, onCancel, onConfirm }: Props) => {
  const { currentSpace } = useContext(AppContext)

  const { register, reset, handleSubmit, setFocus } = useForm()
  const { error, setError, isLoading, updateGymInfo } = useGym({
    onComplete: () => {
      reset()
      onConfirm()
    },
    joinMode: false,
  })

  const save = (data: any) => {
    console.log('data', data)
    updateGymInfo('workoutVisibility', data.workoutVisibility)
  }

  useEffect(() => setFocus('gymName'), [isVisible, error])

  return (
    <Modal
      isVisible={isVisible}
      onCancel={() => {
        reset()
        onCancel()
      }}
      size="small"
      title="Edit Workout Visibility"
      buttons={{
        secondary: {
          onClick: () => {
            reset()
            onCancel()
          },
        },
        primary: {
          onClick: handleSubmit(save),
          label: 'save',
          isSubmitButton: true,
          disabled: error ? true : false,
          isLoading: isLoading,
        },
      }}
    >
      <p>
        Choose when your <i>published</i> workouts become visible to your
        athletes
      </p>
      <form onSubmit={handleSubmit(save)}>
        <select
          defaultValue={currentSpace?.workoutVisibility}
          maxLength={24}
          style={{
            marginTop: '16px',
            borderColor: error ? 'var(--accent-red)' : '',
          }}
          {...register('workoutVisibility', { onChange: () => setError(null) })}
        >
          <option value="evening-before">
            {translateGymData('evening-before')}
          </option>
          <option value="day-ahead">{translateGymData('day-ahead')}</option>
          <option value="week-ahead">{translateGymData('week-ahead')}</option>
          <option value="everything">{translateGymData('everything')}</option>
        </select>

        {error && (
          <p style={{ marginTop: '8px', color: 'var(--accent-red)' }}>
            {error}
          </p>
        )}

        <p
          style={{
            margin: '16px 0 24px',
            font: 'var(--h5)',
            color: 'var(--secondary-text)',
          }}
        >
          Gym staff will always see all workouts
        </p>
      </form>
    </Modal>
  )
}

export default WorkoutVisibility
