import React, { useContext } from 'react'
import { useLocation } from 'react-router-dom'
import { BarLoader } from 'react-spinners'
import CalendarNav from './CalendarNav/CalendarNav'
import css from './SecondaryNav.module.css'
import PlaybookNav from './PlaybookNav/PlaybookNav'
import MembersNav from './MembersNav/MembersNav'
import classNames from 'classnames/bind'
import { usePageContext } from '../../../context/PageContext'
import { AppContext } from '../../../context/AppContext'
import useGymTracks from '../../../hooks/useGymTracks'

type Props = {
  data?: {
    activeType: string
  }
  isLoading?: boolean
  onChange?: (params: any) => void
}

const cx = classNames.bind(css)

const SecondaryNav = ({ data, onChange }: Props) => {
  const { pathname: page } = useLocation()

  const {
    state: { isLoading },
  } = usePageContext()

  const getSecondaryNavContent = () => {
    switch (page) {
      case '/':
        return <CalendarNav />
      case '/playbook':
        return <PlaybookNav />
      case '/members':
        return <MembersNav />
      case '/gym-settings':
        return <h2>Gym Settings</h2>
      case '/account':
        return <h2>Account</h2>
      case '/test':
        return <h2>Test</h2>
      default:
        return <h2>Secondary Title</h2>
    }
  }

  const gymTracks = useGymTracks()
  const calendarHasTracks = page === '/' && gymTracks.length > 1

  return (
    <header
      className={cx('secondary-nav', { calendarWithTracks: calendarHasTracks })}
    >
      {getSecondaryNavContent()}
      {isLoading && (
        <BarLoader
          color="var(--accent-hover)"
          height={3}
          style={{
            position: 'absolute',
            width: '100%',
            height: '4px',
            backgroundColor: 'var(--accent-darker)',
            bottom: '0px',
            overflow: 'hidden',
            marginLeft: '-28px',
          }}
        />
      )}
    </header>
  )
}

export default SecondaryNav
