import React, { useContext, useEffect, useState } from 'react'
import UserList from '../components/sections/UserList/UserList'
import { Radio, Users as IconUsers, UserX, X as IconClose } from 'react-feather'
import '../App.css'
import toast, { Toaster } from 'react-hot-toast'
import { useFirestoreQueryData } from '@react-query-firebase/firestore'
import { ScaleLoader } from 'react-spinners'
import SecondaryNav from '../components/navigation/SecondaryNavigation/SecondaryNav'
import usePeopleManagement from '../hooks/usePeopleManagement'
import { AppContext } from '../context/AppContext'
import MemberPermissions from '../components/modals/MemberPermissions'
import RemoveMember from '../components/modals/RemoveMember'
import ButtonToolbar from '../components/buttons/ButtonToolbar/ButtonToolbar'
import RestoreMember from '../components/modals/RestoreMember'
import { GymMemberData } from '../types/types'
import { UserContext } from '../context/UserContext'
import Footer from '../components/sections/Footer/Footer'
import Page from './technical/Page'
import { BasePageState, usePageContext } from '../context/PageContext'

const Members = () => {
  const [modal, setModal] = useState<{
    data: GymMemberData
    type: 'edit-permissions' | 'remove-member' | 'restore-member'
  } | null>(null)

  const [modalIsVisible, setModalIsVisible] = useState<boolean>(false)
  const { userID } = useContext(UserContext)
  const { setLoading } = usePageContext()

  const onUpdateSuccess = () => {
    toast.success('Member updated successfuly!')
    dismissManageModal()
  }

  const onUpdateFailure = () => {
    toast.error('Something went wrong. Please try again')
  }

  const { currentSpace } = useContext(AppContext)
  const {
    members,
    peopleAreLoading,
    updateInProgress,
    showRemovedMembers,
    restoreMember,
    updateMemberData,
    changeMemberAccess,
    loadRemovedMembers,
  } = usePeopleManagement({
    currentSpace: currentSpace && currentSpace.id,
    onUpdateSuccess: onUpdateSuccess,
    onUpdateFailure: onUpdateFailure,
  })

  useEffect(() => {
    setLoading(peopleAreLoading)
  }, [peopleAreLoading])

  const manageMember = (action: string, data: GymMemberData) => {
    switch (action) {
      case 'edit': {
        setModal({ data: data, type: 'edit-permissions' })
        setTimeout(() => {
          //without timeout it won't show CSS animation
          //when appears
          setModalIsVisible(true)
        }, 0)
        break
      }

      case 'remove': {
        setModal({ data: data, type: 'remove-member' })
        setTimeout(() => {
          //without timeout it won't show CSS animation
          //when appears
          setModalIsVisible(true)
        }, 0)
        break
      }

      case 'restore': {
        setModal({ data: data, type: 'restore-member' })
        setTimeout(() => {
          //without timeout it won't show CSS animation
          //when appears
          setModalIsVisible(true)
        }, 0)
        break
      }
    }
  }

  const dismissManageModal = () => {
    setModalIsVisible(false)
    setTimeout(() => {
      setModal(null)
    }, 250)
  }

  const managers =
    members &&
    members.filter((m: GymMemberData) => m.role === 'manager' && m.hasAccess)
  const coaches =
    members &&
    members.filter((m: GymMemberData) => m.role === 'coach' && m.hasAccess)

  const removed = members && members.filter((m: GymMemberData) => !m.hasAccess)

  return (
    <>
      <Toaster
        toastOptions={{
          success: {
            iconTheme: {
              primary: 'var(--accent-green)',
              secondary: 'white',
            },
            style: {
              borderRadius: '8px',
            },
          },
          error: {
            iconTheme: {
              primary: 'var(--accent-red)',
              secondary: 'white',
            },
            style: {
              borderRadius: '8px',
            },
          },
        }}
      />

      {modal &&
        (modal.type === 'edit-permissions' ? (
          <MemberPermissions
            data={modal.data}
            isVisible={modalIsVisible}
            isLoading={updateInProgress}
            onCancel={dismissManageModal}
            onConfirm={updateMemberData}
          />
        ) : modal.type === 'remove-member' ? (
          <RemoveMember
            data={modal.data}
            isVisible={modalIsVisible}
            isLoading={updateInProgress}
            onCancel={dismissManageModal}
            onConfirm={(data) =>
              changeMemberAccess({ data: data, hasAccess: false })
            }
          />
        ) : (
          <RestoreMember
            data={modal.data}
            isVisible={modalIsVisible}
            isLoading={updateInProgress}
            onCancel={dismissManageModal}
            onConfirm={restoreMember}
          />
        ))}

      <Page isLoading={peopleAreLoading}>
        <div id="page-content" className="col-8 no-subtitle">
          {!peopleAreLoading ? (
            <>
              <section>
                <UserList
                  data={members.filter((m: GymMemberData) => m.hasAccess)}
                  onUserAction={manageMember}
                />
              </section>
              {currentSpace &&
                currentSpace.ownerID === userID &&
                !managers.length &&
                !coaches.length && (
                  <>
                    <section>
                      <div
                        style={{
                          height: '80px',
                          color: 'var(--secondary-text)',
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          borderTop: '1px solid var(--divider)',
                          paddingTop: '24px',
                          marginTop: '-24px',
                        }}
                        className={'placeholder'}
                      >
                        Assign managers or coaches by changing the roles of
                        existing members.
                      </div>
                    </section>
                  </>
                )}

              {removed.length ? (
                !showRemovedMembers ? (
                  <div style={{ marginTop: '40px', display: 'flex' }}>
                    <ButtonToolbar
                      type={'light'}
                      onClick={() => loadRemovedMembers()}
                      icon={<UserX size={16} />}
                    >
                      {`  Show Removed Members (${removed.length})`}
                    </ButtonToolbar>
                  </div>
                ) : (
                  <>
                    <h3>Removed</h3>{' '}
                    <section>
                      <UserList data={removed} onUserAction={manageMember} />
                    </section>
                  </>
                )
              ) : (
                <></>
              )}
            </>
          ) : (
            <></>
          )}
        </div>
      </Page>
    </>
  )
}

export default Members
