import React, { useContext } from 'react'
import {
  DropdownToolbar,
  DropdownToolbarItem,
} from '../DropdownToolbar/DropdownToolbar'
import { AppContext } from '../../../context/AppContext'
import { GymTrack } from '../../../types/types'
import { track } from 'react-facebook-pixel'
import useGymTracks from '../../../hooks/useGymTracks'

type Props = {}

const GymTrackSelector = (props: Props) => {
  const gymTracks = useGymTracks()
  const { currentGymTrack, changeCurrentGymTrack } = useContext(AppContext)

  const getItem = (track: GymTrack): DropdownToolbarItem => {
    return {
      label: track.name,
      id: track.id,
      type: 'value',
    }
  }

  const items = gymTracks
    .map((track) => getItem(track))
    .filter(Boolean) as DropdownToolbarItem[]
  const fallbackTrack = getItem(gymTracks[0])

  const defaultTrack = gymTracks.find((track) => track.id === currentGymTrack)
    ? getItem(gymTracks.find((track) => track.id === currentGymTrack)!)
    : fallbackTrack

  return (
    <DropdownToolbar
      items={items}
      activeItem={defaultTrack}
      onChange={(item) => changeCurrentGymTrack(item)}
      type="borderless"
      style={{
        margin: '2px 0 0 -8px',
      }}
    />
  )
}

export default GymTrackSelector
